import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getJwtTokenFromCurrentSession } from '@utils/getJwtTokeFromCurrentSession';
import { ENVIRONMENT, PLATFORM } from '@aws';
import { processFile } from '@features/files/utils';

const httpLink = createHttpLink({
  uri: PLATFORM[ENVIRONMENT].graphql_endpoint
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getJwtTokenFromCurrentSession();
  return {
    headers: {
      ...headers
      // authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        queryUserFile: {
          keyArgs: ['filter', 'order', 'filter.is_in_trash'], // Кэшируем запросы по этим аргументам,
          merge(existing, incoming) {
            console.log('existing', existing);
            console.log('incoming', incoming);
            if (!existing) {
              return [...incoming.map((file: any) => processFile(file))];
            }
            return [...existing, ...incoming.map((file: any) => processFile(file))];
          }
        }
      }
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network' // Получаем из кэша, но при этом обновляем данные
    }
    // query: {
    //   fetchPolicy: 'cache-first' // Используем кэш в первую очередь
    // }
  },
  cache
});

export default client;
