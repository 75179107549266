import { EntityId } from '@reduxjs/toolkit';

import { DocumentContentType, INTRASH, NOTINTRASH, PRIVATE, RECEIVED, SHARED, ALL } from '@helpers';
import { ListOnItemsRenderedProps } from 'react-window';
import { Sort } from '@utils/types';
import { SxProps } from '@mui/material/styles';
import { ApolloQueryResult } from '@apollo/client';
import { ENVIRONMENT, PLATFORM } from '@aws';
import { ListEntity } from '@shared/components/list-view-v2/types';
import { ListColumnDef } from '@common2/VirtualizedFiles/VirtualizedFilesList/types';
import { FileRef } from '../../__generated__/graphql';

export const LIST = 'list';
export const GRID = 'grid';
export type FilesViewType = typeof LIST | typeof GRID;

export type FileAccessStatus = typeof OWNER | typeof CONTRIBUTOR | typeof EDITOR | typeof VIEWER;

export type FileAccessType =
  | typeof ALL
  | typeof PRIVATE
  | typeof RECEIVED
  | typeof SHARED
  | typeof INTRASH
  | typeof NOTINTRASH;

export type TranscodeStatus = {
  metadata?: string | null;
  thumbnails?: string | null;
  video?: string | null;
};

export const OWNER = 'owner';
export const CONTRIBUTOR = 'contributor';
export const VIEWER = 'viewer';
export const EDITOR = 'editor';

export const ROOT_DIRECTORY = '.';

export interface AccessStatus {
  type: FileAccessStatus;
  level: number;
  label: FileAccessStatus;
  files: File[];
}

export type AcceptableFileExtentions =
  | '.PNG'
  | '.JFIF'
  | '.JPG'
  | '.JPEG'
  | '.AVI'
  | '.WMV'
  | '.MP4'
  | '.MP3'
  | '.MOV'
  | '.FLAC'
  | '.PDF'
  | '.DOCX'
  | '.DOC'
  | '.PPT'
  | '.PPTX'
  | '.TIF'
  | '.TIFF'
  | '.TXT'
  | '.XLS'
  | '.XLSX'
  | '.HTML';

export const MIN_GRID_ITEM_SIZE = 100;
export const MAX_GRID_ITEM_SIZE = 576;

// export interface File {
//   access_status: FileAccessStatus;
//   content_type: DocumentContentType;
//   created: string;
//   description: string;
//   file_id: string;
//   file_size: number;
//   last_updated: string;
//   name: string;
//   access_type: FileAccessType;
//   stream_path: null | string;
//   thumbnails: string[];
//   transcode_status: null | TranscodeStatus;
//   transcode_complete: null | boolean;
//   url: string;
//   thumbnail_url: string;
//   isShared: boolean;
//   isReceived: boolean;
//   parent_dir: string;
//   parent_dir_id: string;
// }

export interface GpraphQLFileContent {
  file_id: string;
  file_type: any;
  content_type: DocumentContentType;
  name: string;
  description: string;
  file_size: number;
  duration: any;
  created: any;
  last_updated: string;
  transcode_status: null | TranscodeStatus;
  transcode_complete: null | boolean;
  parent_dir: {
    name: string;
    file_id: string;
  };
  stream_path: string;
  thumbnails: string;
  comments?: {
    comment_id: string;
  }[];
}

export interface GpraphQLFile {
  access_status: FileAccessStatus;
  access_type: FileAccessType;
  file: GpraphQLFileContent;
}

export interface FileContent extends GpraphQLFileContent {
  url: string;
  thumbnail_url: string;
  access_type: FileAccessType;
  access_status: FileAccessStatus;
}

interface User {
  given_name: string;
  family_name: string;
  email: string;
}

export interface UserAccess {
  user_id: string;
  access_status: FileAccessStatus;
  user: User;
}

export interface FileDetails {
  file_id: string;
  comments: Comment[];
  users: UserAccess[];
  description: string;
}

export interface Comment {
  comment_id: string;
  comment_text: string;
  comment_date: string;
  commentor: Commentor;
  emoticons: Emoticon[];
}

export interface Emoticon {
  id: string;
  emoticon: string;
  commentors: Commentor[];
}

export interface Commentor {
  user_id: string;
  given_name: string;
  family_name: string;
}

export interface File1 extends Omit<GpraphQLFile, 'file'> {
  file: GpraphQLFile['file'] & FileContent;
}

export interface VirtualizedFilesGridProps {
  data: File1[];
  totalItems: number;
  readOnly?: boolean;
  saveScrollPosition?: () => void;
  onFileClick: (file: FileContent) => void;
  setScrollPosition: (newScrollPosition: number) => void;
  onLoadMore: (startIndex: number, stopIndex: number) => Promise<void | ApolloQueryResult<any>>;
}

export interface VirtualizedFilesListProps extends VirtualizedFilesGridProps {
  isDraggable?: boolean;
  columns: ListColumnDef[];
}

export interface VirtualizedGridListItemProps
  extends Omit<VirtualizedFilesGridProps, 'data' | 'onLoadMore'> {
  data: File1[];
  gridItemSize: number;
  height: number;
  width: number;
  onItemsRendered: (props: ListOnItemsRenderedProps) => any; // XZ
}

export interface VirtualizedGridRowItemProps {
  data: {
    files: File1[];
    selectedIds: EntityId[];
    numItemsPerRow: number;
    onSelect: (index: number) => void;
    gridItemSize: number;
    readOnly?: boolean;
    onFileClick: (file: FileContent) => void;
    saveScrollPosition?: () => void;
    lastIndex: number;
  };
  rowIndex: number;
  columnIndex: number;
  style: React.CSSProperties;
}

// export interface GpraphQLFileList {
//   access_status: FileAccessStatus;
//   content_type: DocumentContentType | null;
//   created: string;
//   description: string;
//   file_id: string;
//   last_updated: string;
//   name: string;
//   access_type: FileAccessType;
//   parent_dir_id: string;
//   file: FileRef;
// }

export interface FileBreadcrumb {
  id: string;
  name: string;
  isFile: boolean;
  accessStatus?: FileAccessStatus;
  isUser?: boolean;
  onClick?: () => void;
}

export interface ResponseParams {
  error: string;
  message: string;
}

export interface FetchFilesResponse extends ResponseParams {
  files?: File[];
  url?: string;
}

export interface UploadPart {
  number: number;
  size: number;
  url: string;
}

export interface UploadedFile {
  file_id: string;
  upload_id: string;
  upload_parts: UploadPart[];
  upload_size: number;
  user_id: string;
}

export interface UploadFilesResponse {
  payload: UploadedFile[];
}

interface CreatedDirectory {
  file_id: string;
  user_id: string;
}

export interface CreateDirectoryResponse extends ResponseParams {
  payload: CreatedDirectory[];
}

export interface DownloadIdResponse {
  download_id: string;
}

export interface DownloadUrlResponse {
  status: 'Pending' | 'Ready';
  url?: string;
}

export interface CopyUrlResponse {
  completed: number;
  operation_id: string;
  target_quantity: number;
}

export interface FilesRequestParameters {
  description?: string;
  name?: string;
  dir_id?: string;
}

type FileTypePayload = 'SINGLE_PAYLOAD' | 'EXTENSIVE_PAYLOAD';

export interface ExtensiveFilesRequest<T> {
  files: T;
  parameters: FilesRequestParameters;
}
export interface FilesRequest<T, Payload extends FileTypePayload = 'SINGLE_PAYLOAD'> {
  type?: 'MODIFY' | 'MOVE' | 'COPY' | 'SHARE' | 'SOFT_DELETE' | 'HARD_DELETE' | 'RESTORE';
  payload: Payload extends 'EXTENSIVE_PAYLOAD'
    ? ExtensiveFilesRequest<T>
    : Payload extends 'SINGLE_PAYLOAD'
    ? T
    : never;
}

export interface FilesOperationRequestItem {
  file_id: string;
  share_id?: string;
}

export interface FilesOperationRequest<Payload extends FileTypePayload = 'SINGLE_PAYLOAD'>
  extends FilesRequest<FilesOperationRequestItem[], Payload> {}

export interface ShareFilesRequestItem {
  first_name: string;
  last_name: string;
  target_email: string;
  permission: string;
  shared_files: string[];
}

export interface ShareFilesRequest<Payload extends FileTypePayload = 'SINGLE_PAYLOAD'>
  extends FilesRequest<ShareFilesRequestItem[], Payload> {}

export interface UploadFileCompleteRequestItem {
  file_id: string;
  fileName: string;
  fileType: string;
  filesize: number;
  user_id: string;
  upload_id: string;
  upload_results: {
    part_number: number;
    etag: string;
  }[];
}

export interface UploadFileCompleteRequest<Payload extends FileTypePayload = 'SINGLE_PAYLOAD'>
  extends FilesRequest<UploadFileCompleteRequestItem[], Payload> {}

export interface UploadFileRequestItem {
  file_id: string;
  parent_dir_id: string;
  name: string;
  description: string;
  content_type: DocumentContentType | string;
  upload_size?: number;
}

export interface UploadFilesRequest {
  user_id: string;
  files: UploadFileRequestItem[];
}

export interface UserWithAccess {
  user_id: string;
  given_name: string;
  family_name: string;
  email: string;
  access_status: string;
}

export interface CommentUserData {
  commentor_id: string;
  email: string;
  family_name: string;
  given_name: string;
}

export interface CommentEmoticons {
  emoticon: string;
  commentor_ids: CommentUserData[];
}

export interface CommentEmoticonsSet {
  emoticon: string;
  action: 'add' | 'remove';
  id?: string;
}

export interface FileCommentsResponse {
  comment_id: string;
  owner_id: string;
  commentor_id: string;
  file_id: EntityId;
  comment_text: string;
  emoticons: CommentEmoticons[];

  given_name: string;
  family_name: string;
  comment_date: string;
}

export interface FileDetailsResponse {
  description: string;
  file_id: EntityId;
  message: string;
  users_with_access: UserWithAccess[];
}

export interface DeleteUserDataResponse extends ResponseParams {
  users: string[];
}

export type ExportUsersResponse = DownloadIdResponse & ResponseParams;

export interface ExportUsersParams {
  usersIds: EntityId[];
  userRole: string;
}

export interface ModifyFileResult {
  file_id: string;
  result: string;
}
